import React from "react";
import clsx from "clsx";
import stripMarkdown from "strip-markdown";
import Link from "next/link";
import { Skeleton, Text } from "@jasperlabs/jux-next";
import Image from "next/image";

import SuppressEvent from "components/organisms/SuppressEvent";
import RenderIfCondition from "components/organisms/RenderIfCondition";
import Avatar from "components/organisms/Avatar";
import RenderMarkdown from "components/organisms/RenderMarkdown";

type OfferCardProps = {
	title: string;
	blurb: string;
	imageUrl?: string | null;
	tags?: React.ReactNode;
	href?: string;
	grayscale?: boolean;
	"data-testid"?: string;
};

const MAX_BLURB_LENGTH = 250;

function trimBlurb(blurb: string) {
	if (blurb.length > MAX_BLURB_LENGTH) {
		return `${blurb.substring(0, MAX_BLURB_LENGTH)}...`;
	}

	return blurb;
}

export function OfferCard({
	title,
	blurb,
	imageUrl,
	tags,
	href,
	grayscale = false,
	"data-testid": testId,
}: OfferCardProps) {
	const card = (
		<div
			className={clsx(
				"border border-muted rounded-md h-full",
				href &&
					"cursor-pointer transition-transform duration-200 will-change-transform transform translate-y-0 hover:-translate-y-1 overflow-hidden",
			)}
			data-testid={testId}
		>
			<div className="bg-cover bg-blue-200 bg-center bg-no-repeat p-3 lg:p-5 relative aspect-[5/3]">
				<RenderIfCondition condition={!!imageUrl}>
					<Image
						src={imageUrl!}
						alt={title}
						width={1440}
						height={720}
						className={clsx(
							"w-full h-full absolute object-cover top-0 left-0 z-0",
							grayscale && "grayscale(100%)",
						)}
					/>
				</RenderIfCondition>
				<SuppressEvent>
					<div className="relative z-10">{tags}</div>
				</SuppressEvent>
				<RenderIfCondition condition={!imageUrl}>
					<div className="absolute w-full h-full flex items-center justify-center left-0 top-0">
						<Avatar name={title} size="large" />
					</div>
				</RenderIfCondition>
			</div>
			<div className="p-6">
				<Text as="h3" variant="subheading-lg" className="font-bold mb-4">
					{title}
				</Text>
				<RenderMarkdown
					text={trimBlurb(blurb)}
					options={{
						remarkPlugins: [stripMarkdown],
						components: {
							p: ({ children }) => (
								<Text variant="body-sm" className="mb-2">
									{children}
								</Text>
							),
						},
					}}
				/>
			</div>
		</div>
	);

	if (href) {
		return <Link href={href}>{card}</Link>;
	}

	return card;
}

export function OfferCardLoading() {
	return (
		<div className="border-muted rounded-md border bg-white">
			<Skeleton className="h-56 md:h-72" />

			<div className="px-6 py-8">
				<Skeleton.TextHeading className="w-32" />
				<Skeleton.TextBody rows={2} />
			</div>
		</div>
	);
}
